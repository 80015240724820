import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Photo from '../components/Photo'
import Share from '../components/Share'

import { themeStyles } from '../utils/theme'

class Places extends React.Component {
  render() {
    let posts = get(this, 'props.data.allMarkdownRemark.edges') || []
    posts = posts.filter(post => post.node.frontmatter.published)
    const postElements = posts.map(({ node }) => {
      const title = get(node, 'frontmatter.title')
      const imageResponsive = get(node, 'frontmatter.featuredImage.childImageSharp.fluid', '../../assets/blank.gif')
      return <Photo key={node.id} slug={node.fields.slug} title={title} imageResponsive={imageResponsive}/>
    })
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Places"
          keywords={keywords}
        />
        <div css={themeStyles.textPadding}>
          {postElements}
        </div>
        <Share slug="/places" title={siteTitle} excerpt={siteDescription} />
      </Layout>
    )
  }
}

export default Places

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allMarkdownRemark(filter: {fields: {slug: { regex: "/places/"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            published
          }
        }
      }
    }
  }
`